import React from 'react';
import { CityLocation, getCountryFromLocale } from '../src/helpers/location';
import { getCommonFlippData, RouteProps } from '../src/helpers/common';
import Home from '../src/components/home';
import { getFlags } from '../src/helpers/launchDarkly';

interface RootRouteProps extends RouteProps {
  cities: CityLocation[];
  shouldUseBlackFridayDesign: boolean;
}

export default function RootRoute({ cities, shouldUseBlackFridayDesign }: RootRouteProps): JSX.Element {
  return <Home cities={cities} shouldUseBlackFridayDesign={shouldUseBlackFridayDesign} />;
}

export const getStaticProps = async ({ locale }) => {
  const countryFromLocale = getCountryFromLocale(locale);
  const { cities } = await getCommonFlippData(countryFromLocale, locale);

  const { shouldUseBlackFridayDesign } = await getFlags();
  return {
    props: { cities, shouldUseBlackFridayDesign },
  };
};
